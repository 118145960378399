import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import store from '@/store'
import { FamilyDetailsState } from './types'
import { fetchFamily } from '@/api/families'

// eslint-disable-next-line import/prefer-default-export
export const appFamilyDetails = createModule(
  'appFamilyDetails',
  {
    namespaced: true,
    state: {
      familyId: null,
      isLoading: false,
      reloadData: false,
      parentsList: [],
      family: {
        attributes: [],
      },
      familyAttributesColumns: [
        {
          key: 'label', label: 'label', sortable: false, class: 'table-col',
        },
        {
          key: 'value', label: 'value', sortable: false, class: 'table-col',
        },
      ],
    } as FamilyDetailsState,
    getters: {
      getIsLoading: state => state.isLoading,
      getFamilyId: state => state.familyId,
      getFamily: state => state.family,
      getFamilyAttributes: state => state.family?.attributes ?? [],
      getFamilyAttributesColumns: state => state.familyAttributesColumns,
      getParentsList: state => state.parentsList,
      reloadData: state => state.reloadData,
    },
    mutations: {
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_FAMILY_ID(state, val) {
        state.familyId = val
      },
      SET_PARENTS_LIST(state, val) {
        state.parentsList = val
      },
      SET_FAMILY(state, val) {
        state.family = val
      },
      INIT_STATE(state) {
        state.isLoading = false
      },
      RELOAD_DATA(state) {
        state.reloadData = !state.reloadData
      },
    },
    actions: {
      async fetchFamilyData({ state }) {
        try {
          const response = await fetchFamily(state.familyId)
          appFamilyDetails.mutations.SET_FAMILY(response.data.data)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Family Details',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appFamilyDetails.register(store)
