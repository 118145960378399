<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    variant="white"
    opacity="1"
    rounded="sm"
  >
    <div
      v-click-outside="closeEditing"
      class="d-flex align-items-center"
    >
      <v-select
        :ref="familyMemberId"
        v-model="selectedValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :multiple="false"
        :clearable="false"
        :options="relations"
        placeholder="Select Relation type"
        class="per-page-selector d-inline-block ml-50 mr-1 relation-selector"
      />

      <feather-icon
        icon="SaveIcon"
        size="18"
        class="text-primary cursor-pointer flex-shrink-0 ml-1"
        @click="applyChanges"
      />
    </div>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import { BOverlay } from 'bootstrap-vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import Vue from 'vue'
import vClickOutside from 'v-click-outside'
import { appParentsList } from '@/store/modules/app-parents/app-parenst-list'

Vue.use(vClickOutside)

export default Vue.extend({
  name: 'FamilyRelationSelector',
  components: {
    vSelect,
    BOverlay,
  },
  props: {
    familyMemberId: {
      type: [String, Number],
      required: true,
    },
    selectedRelation: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isLoading: false,
      selectedValue: '',
      relations: [
        'father',
        'mother',
        'stepmother',
        'stepfather',
        'grandmother',
        'grandfather',
        'guardian',
        'other',
      ],
    }
  },
  created() {
    this.selectedValue = this.selectedRelation
  },
  methods: {
    async applyChanges() {
      try {
        const queryParams = {
          id: this.familyMemberId,
          relationship: this.selectedValue,
        }
        this.isLoading = true

        appParentsList.actions.updFamilyMember(queryParams)
        this.$emit('updated')
      } finally {
        this.isLoading = false
        this.closeEditing()
      }
    },
    closeEditing() {
      this.$emit('closeEditing')
    },
  },
})
</script>

<style scoped lang="scss">
.relation-selector {
  min-width: 200px;
  max-width: 300px;
}
</style>
