import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import {
  FamilyAttributesListState,
  IFamilyAttributesListRequest,
  IFamilyAttributesListFilterObject,
} from './types'
import {
  fetchFamilyAttributeList,
  updateFamilyAttribute,
  deleteFamilyAttribute,
} from '@/api/familyAttributes'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

// eslint-disable-next-line import/prefer-default-export
export const appFamilyAttributesList = createModule(
  'appFamilyAttributesList',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'label', label: 'label', sortable: true },
        { key: 'value', label: 'value', sortable: true },
        { key: 'actions', label: 'actions', sortable: false },
      ],
      perPage: Number(localStorage.getItem('familyAttributesPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '' as string | (string | null)[],
      sortBy: 'created_at',
      isSortDirDesc: true,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      manageableFieldId: null,
      manageableFieldName: null,
      manageableFieldValue: null,
      isLoading: false,
    } as FamilyAttributesListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getDataMeta: state => state.dataMeta,
      getManageableFieldId: state => state.manageableFieldId,
      getManageableFieldName: state => state.manageableFieldName,
      getManageableFieldValue: state => state.manageableFieldValue,
      getIsLoading: state => state.isLoading,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('familyAttributesPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      CLEAR_FILTERS(state) {
        state.searchQuery = ''
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('familyAttributesPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
      },
      SET_MANAGEABLE_FIELD_ID: (state, val) => {
        state.manageableFieldId = val
      },
      SET_MANAGEABLE_FIELD_NAME: (state, val) => {
        state.manageableFieldName = val
      },
      SET_MANAGEABLE_FIELD_VALUE: (state, val) => {
        state.manageableFieldValue = val
      },
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
    },
    actions: {
      async fetchFamilyAttributesList({ state }, filter: IFamilyAttributesListFilterObject) {
        try {
          const queryParams: IFamilyAttributesListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
          }
          if (state.searchQuery) queryParams.search = state.searchQuery

          if (filter.name === 'familyId') {
            queryParams.familyId = filter.value
          }

          const response:any = await fetchFamilyAttributeList(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appFamilyAttributesList.mutations.SET_TOTAL_ITEMS(total)
          appFamilyAttributesList.mutations.SET_DATA_META(dataMeta)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching family fields list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async deleteFamilyAttribute(ctx, id) {
        try {
          await deleteFamilyAttribute(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success manual deleting Family Attribute',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting Family Attribute',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async updateFamilyAttribute({ state }) {
        try {
          const updatedFamilyAttribute = {
            id: state.manageableFieldId,
            value: state.manageableFieldValue,
          }
          await updateFamilyAttribute(updatedFamilyAttribute)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Family Attribute was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating Family Attribute',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appFamilyAttributesList.register(store)
