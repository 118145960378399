import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useParentsChildrenList() {
  // Use toast
  const toast = useToast()

  const fetchChildren = ID => axios
    .get('/auth/users', {
      params: {
        family_id: ID,
        role: 'child',
      },
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Parents',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const linkStudent = queryParams => axios
    .post('/auth/users/invite-to-family', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'The invitation was successfully send',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating Fee',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    })

  const addStudentToFamily = queryParams => axios
    .post('/auth/family/member/add', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Successfully',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    })

  const fetchCampersForSelectList = queryParams => axios
    .get('/auth/campers-for-select', {
      params: queryParams,
    })
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Campers',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteUser = ID => axios
    .delete(`/auth/users/${ID}`)
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteUserFromFamily = ID => axios
    .delete(`/auth/family/member/${ID}/delete`)
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchFamilyMembers = queryParams => axios
    .get('/auth/family/family-members', {
      params: queryParams,
    })
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Families',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchChildren,
    linkStudent,
    fetchCampersForSelectList,
    addStudentToFamily,
    deleteUser,
    deleteUserFromFamily,
    fetchFamilyMembers,
  }
}
