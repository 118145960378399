import axios from '@axios'

export const fetchFamilyAttributeList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/family-attributes', { params })
  return res
}

export const fetchFamilyAttribute = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/family-attributes/${id}`)
  return res
}

export const updateFamilyAttribute = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/family-attributes/${params.id}`, params)
  return res
}

export const deleteFamilyAttribute = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/family-attributes/${id}`)
  return res
}
