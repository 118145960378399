<template>
  <ValidationObserver
    ref="addCamperToFamilyRules"
  >
    <b-modal
      id="add-camper-to-family-modal"
      ref="add-camper-to-family-modal"
      cancel-variant="outline-secondary"
      centered
      :title="'Add Child'"
      ok-title="Save"
      no-close-on-backdrop
      @show="prepareData"
      @hidden="closeModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              :label="'Available children for adding to family'"
            >
              <validation-provider
                #default="{ errors }"
                :name="`${camperStudent(1)}`"
                rules="required"
              >
                <v-select
                  v-model="chosenMembers"
                  :options="campersList"
                  :filterable="false"
                  label="text"
                  value="value"
                  :placeholder="campersFieldStatus"
                  :reduce="option => option.value"
                  :class="{'is-invalid': errors.length > 0 }"
                  multiple
                  @search="onSearch"
                >
                  <li
                    v-if="metaData.current_page"
                    slot="list-footer"
                    class="pagination d-flex  align-items-center justify-content-between my-1 mx-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      :disabled="!hasPrevPage"
                      @click="currentPage--"
                    >
                      Prev
                    </b-button>
                    <span>{{ currentPage }} of {{ metaData.last_page }}</span>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      :disabled="!hasNextPage"
                      @click="currentPage++"
                    >
                      Next
                    </b-button>
                  </li>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading"
          @click="handleForm"
        >
          Add To Family
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import useParentsChildrenList from '@/views/components/parents-children-list/useParentsChildrenList'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    familyId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      campersFieldStatus: 'Loading...',
      chosenMembers: null,
      currentPage: 1,
      searchQuery: null,
      campersList: [],
      metaData: {},
      validationErrors: '',
      isLoading: true,
      required,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
    }),
    hasNextPage() {
      return this.metaData.current_page !== this.metaData.last_page
    },
    hasPrevPage() {
      return this.currentPage > 1
    },
  },
  watch: {
    currentPage() {
      this.fetchingCampersForAdd()
    },
    searchQuery() {
      this.fetchingCampersForAdd()
    },
  },
  setup() {
    const {
      fetchCampersForSelectList,
      addStudentToFamily,
    } = useParentsChildrenList()

    return {
      fetchCampersForSelectList,
      addStudentToFamily,
    }
  },
  methods: {
    async prepareData() {
      this.chosenMembers = null
      this.currentPage = 1
      this.searchQuery = null
      this.metaData = {}
      this.campersList = []
      await this.fetchingCampersForAdd()
      this.isLoading = false
    },
    async fetchingCampersForAdd() {
      const payloadData = {
        programId: this.programId,
        perPage: 25,
        page: this.currentPage,
        sortBy: 'name',
        sortDesc: false,
        availableForAddToFamily: true,
        userRole: 'child',
      }
      if (this.searchQuery) payloadData.name = this.searchQuery
      await this.fetchCampersForSelectList(payloadData)
        .then(response => {
          this.campersList = response.data.data.map(item => ({ value: item.user_id, text: item.full_name, id: item.id }))
          this.metaData = response.data.meta
          this.campersFieldStatus = 'Select Child'
        })
    },
    async closeModal() {
      this.$refs['add-camper-to-family-modal'].hide()
      this.$emit('success')
    },
    async handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      // eslint-disable-next-line consistent-return
      return new Promise(() => {
        this.$refs.addCamperToFamilyRules.validate().then(success => {
          if (success) {
            this.sendRequest()
          }
        })
      })
    },
    async onSearch(query) {
      this.searchQuery = query
      this.currentPage = 1
    },
    async sendRequest() {
      const queryParams = {
        family_id: this.familyId,
        user_id: this.chosenMembers,
      }
      this.isLoading = true
      await this.addStudentToFamily(queryParams)
        .then(() => {
          this.$emit('success')
          this.closeModal()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
