<template>
  <ValidationObserver
    ref="link-parent"
  >
    <b-modal
      id="link-parent-modal"
      ref="link-parent-modal"
      centered
      title="Link A Parent"
      ok-title="Save"
      ok-only
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email|required"
              >
                <b-form-input
                  v-model="parentEmail"
                  type="text"
                  :placeholder="'Email'"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import useUserParentsList from '@/views/components/parents-list/useUserParentsList'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    userId: {
      type: Number,
      default: () => null,
    },
    familyId: {
      type: [String, Number],
      default: () => null,
    },
  },
  data() {
    return {
      parentEmail: null,
      isLoading: false,
      validationErrors: '',
      authUserData: JSON.parse(localStorage.getItem('userData')),
      required,
      email,
    }
  },
  setup() {
    const {
      linkParent,
    } = useUserParentsList()

    return {
      linkParent,
    }
  },
  methods: {
    closeModal() {
      this.parentEmail = null
      this.validationErrors = ''
      this.$refs['link-parent-modal'].hide()
    },
    handleForm() {
      this.validationErrors = ''

      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs['link-parent'].validate().then(success => {
          if (success) {
            resolve(true)
            this.sendRequest()
          } else {
            reject()
          }
        })
      })
    },
    async sendRequest() {
      const queryParams = {
        email: this.parentEmail,
        family_id: this.familyId,
        role: 'parent',
      }

      if (this.userId) {
        queryParams.user_id = this.userId
      }

      this.isLoading = true
      await this.linkParent(queryParams)
        .then(() => {
          this.$emit('success')
          this.closeModal()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
